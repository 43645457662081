<route>
{
  "meta": {
    "permission": [
      "base_config.view_term"
    ]
  }
}
</route>

<template>
  <div>
    <dialterms v-model="dialog" :to-edit="toEdit" @done="reload = true" />
    <i-table
      :title="$tc('terms', 2)"
      :headers="headers"
      app="base_config.terms"
      api="terms"
      :reload.sync="reload"
      @click:create="open()"
      @click:edit="open($event)"
    >
    </i-table>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import dialterms from '../../components/terms/modal.vue'
export default {
  components: {
    dialterms
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('days'),
          value: 'days'
        },
        {
          text:
            this.termsBtn.delete || this.termsBtn.edit ? this.$t('action') : '',
          value: this.termsBtn.delete || this.termsBtn.edit ? 'actions' : ''
        }
      ]
    },
    ...mapGetters({
      getPermissions: 'session/getPermissions',
      isAdmin: 'session/isAdmin'
    }),
    termsBtn() {
      return {
        delete:
          this.getPermissions([`base_config.delete_term`]) || this.isAdmin,
        edit: this.getPermissions([`base_config.change_term`]) || this.isAdmin
      }
    }
  },
  data() {
    return {
      reload: false,
      dialog: false,
      toEdit: null
    }
  },
  methods: {
    open(item) {
      this.toEdit = item === undefined ? null : item
      this.dialog = true
    }
  }
}
</script>
